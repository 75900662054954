import { navigate } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { FaTimesCircle } from 'react-icons/fa';

import Container from 'components/Container/Container';
import HeroText from 'components/HeroText/HeroText';
import InnerContainer from 'components/InnerContainer/InnerContainer';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Title from 'components/Title/Title';

import useParam from 'hooks/useParam';

// import { banner } from 'styles/pages/common.module.scss';

const iconMb = { marginBottom: '1rem' };

const FailurePage = () => {
  const isUnmounted = useRef(false);
  const orderId = useParam('orderId');

  useEffect(() => {
    if (!isUnmounted.current && !orderId) {
      if (typeof window !== 'undefined') {
        navigate('/');
      }
      return null;
    }

    return () => {
      isUnmounted.current = true;
    };
  }, [isUnmounted, orderId]);

  return (
    <Layout fullWidth>
      <Seo title="Η συναλλαγή δεν ήταν επιτυχής" />
      <HeroText title="ΑΠΟΤΥΧΙΑ" subtitle="ΑΠΟΤΥΧΙΑ ΣΥΝΑΛΛΑΓΗΣ" />
      <Container withPadding>
        <InnerContainer>
          <Title>Η συναλλαγή δεν ήταν επιτυχής</Title>
          <FaTimesCircle size="2.5rem" color="#cf000f" style={iconMb} />
          <p>
            Δυστυχώς, η συναλλαγή με κωδικό <b>{orderId}</b> δεν ολοκληρώθηκε με
            επιτυχία!
          </p>
          <p>
            Μπορείτε να μας καλέσετε στα τηλέφωνα επικοινωνίας για να σας
            βοηθήσουμε να ολοκληρώσετε επιτυχώς την παραγγελία σας.
          </p>
        </InnerContainer>
      </Container>
    </Layout>
  );
};
export default FailurePage;
